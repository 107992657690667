/**
 * Return HTML Element
 *
 * @param {string} selector - Selector
 * @param {string} context - Context
 * @return {HTMLElement} A good string
 *
 * @example
 *     q('.class', 'contex')
 */

export const q = (selector, context) => {
  return /** @type {HTMLElement} */ ((context || document).querySelector(selector))
}

/**
 * Return HTML Elements Array
 *
 * @param {string} selector - Selector
 * @param {string} context - Context
 * @return {Array.<HTMLElement>} A good string
 *
 * @example
 *     qq('.class', 'contex')
 */

export const qq = (selector, context) => {
  return Array.from((context || document).querySelectorAll(selector))
}
